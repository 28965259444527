import React from 'react'
import EditAdmin from '../components/admin/EditAdmin'
import { Link } from 'react-router-dom'
import cog from '../img/icon-cog.svg';


export default function UpdateAdmin() {
    return (
        <>
       <div>
          <div className="toolbox">
                <div className="user-id">
                    <img src={cog} width="16" alt="" /> MODIFIER UN ADMINISTRATEUR

                </div>
                <Link to="/admin" className="back-btn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i> Retour à la liste des administrateurs
                </Link>
          </div>
         <EditAdmin />
        </div>
    </>
    )
}
