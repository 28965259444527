import React from 'react'
import Event from '../components/users/Event'

export default function Events() {
    return (
        <div>
            <Event />
        </div>
    )
}
