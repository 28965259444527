import AdminTable from '../components/admin/AdminTable'
import React, { useState, useEffect } from 'react';
import Loader from '../components/Loader'
import cog from '../img/icon-cog.svg';


export default function ListAdmin() {

  const [loader, setLoader] = useState(true)

  useEffect(() => {

    setTimeout(() => {
        setLoader(false)
    }, 2000)

  }, [])
  
  return loader ?
  (
    <Loader />
  ) : 
  (
    <>
     

      <AdminTable />
    </>
  )
}
