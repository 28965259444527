import React from 'react'
import ViewVehicule from "../components/users/ViewVehicule" 

export default function VehiculeView() {
    return (
        <div>
            <ViewVehicule />
        </div>
    )
}
