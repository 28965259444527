import React, {useState, useEffect} from 'react'
import { useParams, Link } from "react-router-dom";
import licences2 from '../../img/icon-user-2.svg';
import edit from '../../img/icon-edit.svg';
import DayJS from 'react-dayjs';
import { storage } from "../firebaseConfig";



const ViewLicence = () => {

    const myValue = JSON.parse(localStorage.getItem('user'))['token'];
    

    const [licences, setLicence] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        loadLicense();
    }, []);
      
  
    const loadLicense = async () => {

        try {
         const config = {
           method: 'GET',
           // mode: 'no-cors',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'X-token-api': myValue
           }
         };
         // Envoyer les informations à notre server avec fetch
         const ress = fetch(
             process.env.REACT_APP_API_URL + "/license/list",
           config
         ).then(response => response.json())
         .then(result => setLicence(result)); 
         console.log(licences)
              
       } catch (error) {
         console.error(error);
        //  setError(error);
       }
             

     }

   

    return (
        <div>
        <div className="toolbox">
                <div className="user-id">
                    <img src={licences2} width="16" alt="" /> USER ID {id}
                </div>
                <Link to="/licences" className="back-btn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i> Retour à la liste des utilisateurs
                </Link>
        </div>
            <nav className="content-nav">
                <div className="nav" id="nav-tab" role="tablist">
                    <Link className="nav-link" id="nav-IDENTITE-tab" data-toggle="tab" to={`/users/view/${id}`} role="tab" aria-controls="nav-IDENTITE" aria-selected="true">IDENTITE</Link>
                    <Link className="nav-link active" id="nav-PERMIS-tab" data-toggle="tab" to={`/licence/view/${id}`} role="tab" aria-controls="nav-PERMIS" aria-selected="false">PERMIS</Link>
                    <Link className="nav-link" id="nav-VEHICULES-tab" data-toggle="tab" to={`/vehicule/view/${id}`} role="tab" aria-controls="nav-VEHICULES" aria-selected="false">VEHICULES</Link>
                    <Link className="nav-link" id="nav-ÉVÉNEMENTS-tab" data-toggle="tab" to={`/events/view/${id}`} role="tab" aria-controls="nav-ÉVÉNEMENTS" aria-selected="false">ÉVÉNEMENTS</Link>
                    <Link className="nav-link" id="nav-DOCUMENTS-tab" data-toggle="tab" to={`/document/view/${id}`} role="tab" aria-controls="nav-DOCUMENTS" aria-selected="false">DOCUMENTS</Link>
                    <Link className="nav-link" id="nav-POINTS-tab" data-toggle="tab" to={`/points/view/${id}`} role="tab" aria-controls="nav-POINTS" aria-selected="false">POINTS</Link>
                </div>
            </nav>
        <div className="tab-pane fade show active" id="nav-PERMIS" role="tabpanel" aria-labelledby="nav-PERMIS-tab">
            {licences.map((licence) => (
                    <div className="card-content">
                        
                        <div className="card-content-header">
                            <div className="card-content-title">
                                PERMIS
                            </div>
                            <div className="card-content-tool">
                                <a href="#">
                                    <img src={edit} alt="" />modifier
                                </a>
                            </div>
                        </div>
                        <div className="card-content-body">
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    Numéro :
                                </div>
                                <div className="card-b-info">
                                {licence.number}
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    DATE :
                                </div>
                                <div className="card-b-info">
                                    <DayJS format="DD-MM-YYYY">{licence.delivered_at}</DayJS>
                                </div> 
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    TYPE :
                                </div>
                                <div className="card-b-info">
                                {licence.license_type.map((lice) => (
                                    <div>{lice.type.libelle}</div>
                                    ))} 
                                    {/* {licence.license_type} */}
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    PAYS :
                                </div>
                                <div className="card-b-info">
                                 {licence.country.libelle}
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    FICHIER :
                                </div>
                                <div className="card-b-info card-b-min">
                                    <a href={licence.card ? licence.card.scan_id : ""}>{licence.card ? "Photo Permis/Pièce d'identité" : ""}</a>    
                                                            
                                </div>
                                <div className="card-c-info">
                                    <button>
                                        
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                        <a style={{color: "#FFFFFF"}} href={licence.card ? licence.card.scan_id : "#"}>CONSULTER</a>
                                    </button>
                                    <button className="bg-gray">
                                        <i className="fa fa-download" aria-hidden="true"></i>
                                        <a style={{color: "#FFFFFF"}} title="ImageName" download  >TÉLÉCHARGER</a>
                                        
                                    </button>
                                    {/* <a download="custom-filename.jpg" href={licence.card.scan_id} title="ImageName">
                                        <img alt="ImageName" src={licence.card.scan_id} />
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
    )
}
export default ViewLicence  