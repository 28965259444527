import React from 'react'

const Loader = () => {
    return (
        <div style={{marginTop: "350px", marginLeft: "350px"}}>
             <div className="d-flex justify-content-center mt-5">
                 <div className="spinner-border text-danger" style={{width: "4rem", height: "4rem"}} role="status">
                     <span className="sr-only">Loading...</span>
                </div>
             </div>   
        </div>
       
    );
};

export default Loader;