import React, {useEffect, useState} from 'react'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory from 'react-bootstrap-table2-filter';
import {Link} from 'react-router-dom'
import DayJS from 'react-dayjs';




const AdminTable = () => {

   const [users, setUsers] = useState([]);
   const [error, setError] = useState(null);

   const myValue = JSON.parse(localStorage.getItem('user'))['token'];


  

   const deleteAdmin = async id => {
    
    const config = {
    method: 'POST',
    // mode: 'no-cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-token-api': myValue
    }
  };
      await axios.delete(process.env.REACT_APP_API_URL + `/user/delete/${id}`, config);
      listAdmin ();
      // window.location.reload(false);
   }

    const columns = [
        {dataField:'id', text: 'ID', sort: true},
        {dataField:'lastname', text: 'Nom', sort: true},
        {dataField:'firstname', text: 'Prénom', sort: true},
        {
          dataField: 'created_at', text: 'Inscription', sort: true,
          formatter: (users, user) => {
            return (    
              <DayJS format="DD-MM-YYYY">{user.created_at}</DayJS>
            )
          }
        },
        {dataField:'email', text: 'Email', sort: true},
        {
          dataField: 'is_admin', text: 'Admin', sort: true,
          formatter: (users, user) => {
            return (    
              <span>{user.is_admin === 1 ? "✅oui" : "❌non"}</span> 
            )
          }
        },
        {
          dataField: 'iddd',
          text: '',
          formatter: (users, user) => {
            return (    
              <Link className="btn btn-primary" to={`/admin/edit/${user.id}`}>
                Modifier
              </Link>
            )
          }
        },
        {
            dataField: 'idd',
            text: '',
            formatter: (users, user) => {
              let button;
              if (user.suspended_at === null) {
                  button =  <button onClick={() => disableUser(user.id)} className={user.suspended_at === null ? "btn btn-warning" : "btn btn-success"}>
                  {user.suspended_at === null ? "Désactiver" : "Activer"}
              </button>
              } else {
                  button =  <button onClick={() => activeUser(user.id)} className={user.suspended_at === null ? "btn btn-warning" : "btn btn-success"}>
                  {user.suspended_at === null ? "Désactiver" : "Activer"}
              </button>
              }
              return (    
               <div>{button}</div>
           
              )
            }
        }
        
        // {dataField:'is_active', text: 'Statut', sort: true},
    ]
    const pagination = paginationFactory ({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage){
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    });

    useEffect(() => {
      listAdmin();
    }, []);

    const disableUser = async id => {
      const config2 = {
        method: 'GET',
        // mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-token-api': myValue
        }
      };
 
     await axios.get(process.env.REACT_APP_API_URL + `/user/disable/${id}`, config2).then(() => {
       
      listAdmin();           

     });
    }

    const activeUser = async id => {

      const config2 = {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-token-api': myValue
          }
        };
   
       await axios.get(process.env.REACT_APP_API_URL + `/user/active/${id}`, config2).then(() => {
         
        listAdmin(); 
             
  
       });
    }
    console.log(users.filter(user => user.is_admin))

    // const listAdminn = users.filter((user) => {(user.is_admin)})

    const listAdmin  = async () => {

        try {
          const config = {
            method: 'POST',
            // mode: 'no-cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-token-api': myValue
            }
          };
          // Envoyer les informations à notre server avec fetch
          const res = fetch(
            process.env.REACT_APP_API_URL + '/user/list',
            config
          ).then(response => response.json())
          .then(result => setUsers(result.filter((user) => user.is_admin)));
  
          console.log('The rest')
          console.log(res)
          // debugger
         
        } catch (error) {
          console.error(error);
          setError(error);
        }  
    };


    return (
    <>
      <div className="toolbox">
      <div className="user-id">
          <img src="" width="16" alt="" /> LISTE DES ADMINISTRATEURS CODENEKT
      </div>
      <div className="filter-btn">
          Filtrer par :
          <select name="filter"defaultValue="2" id="filter">
              <option value="1" >Nom</option>
              <option value="2" >Prénom</option>
              <option value="3" >Email</option>
              <option value="4" >ID</option>
          </select>
      </div>
      <div className="search-input">
          <input type="text" placeholder="Rechercher un administrateur" />
          <i className="fa fa-search" aria-hidden="true"></i>
      </div>
  </div>
      <div className="tab-user pt-3" style={{width: "900px"}}>
            
    <BootstrapTable bootstrap4
    classes="table table-striped"
     keyField='id' 
     columns={columns}
     data={users} 
     pagination={pagination}
     filter={filterFactory()}
     striped bordered hover />
    
  
        </div>
        </>
    )
}

export default AdminTable
