import React, {useEffect, useState} from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import {Link, useParams} from 'react-router-dom'
import DayJS from 'react-dayjs';
import axios from 'axios';



const UserTable = () => {

   const [users, setUsers] = useState([]);
   const [error, setError] = useState(null);
   
   const myValue = JSON.parse(localStorage.getItem('user'))['token'];
   const { id } = useParams();
   
  

   const columns = [
        {dataField:'id', text: 'ID', sort: true},
        {dataField:'email', text: 'Email', sort: true},
        {dataField:'firstname', text: 'Prénom', sort: true},
        {dataField:'lastname', text: 'Nom', sort: true},
        {
          dataField: 'created_at', text: 'Inscription', sort: true,
          formatter: (users, user) => {
            return (    
              <DayJS format="DD-MM-YYYY">{user.created_at}</DayJS>
            )
          }
        },
        {
          dataField: 'is_active', text: 'Statut', sort: true,
          formatter: (users, user) => {
            return (   
              <span>{user.suspended_at === null ? "✅actif" : "❌désactivé"}</span> 
            
            )
          }
        },
        {
          dataField: 'iddd', text: '',
          formatter: (users, user) => {
            
            return (    
              <Link className="btn btn-primary" to={`/users/view/${user.id}`}>
                  Consulter
              </Link>
            )
          }
      },
        {
            dataField: 'idd', text: '',
            formatter: (users, user) => {
              let button;
              if (user.suspended_at === null) {
                  button =  <button onClick={() => disableUser(user.id)} className={user.suspended_at === null ? "btn btn-warning" : "btn btn-success"}>
                  {user.suspended_at === null ? "Désactiver" : "Activer"}
              </button>
              } else {
                  button =  <button onClick={() => activeUser(user.id)} className={user.suspended_at === null ? "btn btn-warning" : "btn btn-success"}>
                  {user.suspended_at === null ? "Désactiver" : "Activer"}
              </button>
              }
              return (    
               <div>{button}</div>
           
              )
            }
        }
    ]

    const pagination = paginationFactory ({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage){
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    });
      
    useEffect(() => {
      loadUser();  
        
    }, [])
    // console.log(users.firstname)

    const disableUser = async id => {
      const config2 = {
        method: 'GET',
        // mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-token-api': myValue
        }
      };
 
     await axios.get(process.env.REACT_APP_API_URL + `/user/disable/${id}`, config2).then(() => {
       
    loadUser();           

     });
    }

    const activeUser = async id => {

      const config2 = {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-token-api': myValue
          }
        };
   
       await axios.get(process.env.REACT_APP_API_URL + `/user/active/${id}`, config2).then(() => {
         
        loadUser(); 
             
  
       });
    }
 

    const loadUser = async () => {

      try {
        const config = {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-token-api': myValue
          }
        };
        // Envoyer les informations à notre server avec fetch
        fetch(
          process.env.REACT_APP_API_URL + '/user/list',
          config
        ).then(response => response.json())
        .then(result => setUsers(result));
       
      } catch (error) {
        console.error(error);
        setError(error);
      }
    }

   
    

    return (
       
    <div className="tab-user pt-3" style={{width: "1000px"}}>
            
    <BootstrapTable bootstrap4
     classes="table table-striped"
     keyField='id' 
     columns={columns}
     data={users} 
     pagination={pagination}
     filter={filterFactory()}
     striped bordered hover />
    
    </div>

    )
}

export default UserTable
