import React, {useState, useEffect} from 'react'
import { useParams, Link } from "react-router-dom";
import users2 from '../../img/icon-user-2.svg';
import edit from '../../img/icon-edit.svg';
import supEvent from '../../img/icon-trash.svg';
import axios from 'axios';




const ViewLicence = () => {

    const myValue = JSON.parse(localStorage.getItem('user'))['token'];


    const [events, setEvents] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        loadEvents();
    }, []);

    const loadEvents = async () => {

        try {
         const config = {
           method: 'GET',
           // mode: 'no-cors',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'X-token-api': myValue
           }
         };
         // Envoyer les informations à notre server avec fetch
         const ress = fetch(
            process.env.REACT_APP_API_URL + "/event/list",
           config
         ).then(response => response.json())
         .then(result => setEvents(result)); 
         console.log(ress)
              
       } catch (error) {
         console.error(error);
         // setError(error);
       }  
       

     }
     
     const deleteEvents = async id => {

        const config = {
            method: 'DELETE',
            // mode: 'no-cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-token-api': myValue
            }
          };
     
        await axios.delete(process.env.REACT_APP_API_URL + `/event/delete/${id}`, config);
        loadEvents(); 
        
      }
    return (
        <div>
        <div className="toolbox">
                <div className="user-id">
                    <img src={users2} width="16" alt="" /> USER ID {id}
                </div>
                <Link to="/users" className="back-btn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i> Retour à la liste des utilisateurs
                </Link>
        </div>
            <nav className="content-nav">
                <div className="nav" id="nav-tab" role="tablist">
                    <Link className="nav-link" id="nav-IDENTITE-tab" data-toggle="tab" to={`/users/view/${id}`} role="tab" aria-controls="nav-IDENTITE" aria-selected="true">IDENTITE</Link>
                    <Link className="nav-link" id="nav-PERMIS-tab" data-toggle="tab" to={`/licence/view/${id}`} role="tab" aria-controls="nav-PERMIS" aria-selected="false">PERMIS</Link>
                    <Link className="nav-link" id="nav-VEHICULES-tab" data-toggle="tab" to={`/vehicule/view/${id}`} role="tab" aria-controls="nav-VEHICULES" aria-selected="false">VEHICULES</Link>
                    <Link className="nav-link active" id="nav-ÉVÉNEMENTS-tab" data-toggle="tab" to={`/events/view/${id}`} role="tab" aria-controls="nav-ÉVÉNEMENTS" aria-selected="false">ÉVÉNEMENTS</Link> 
                    <Link className="nav-link" id="nav-DOCUMENTS-tab" data-toggle="tab" to={`/document/view/${id}`} role="tab" aria-controls="nav-DOCUMENTS" aria-selected="false">DOCUMENTS</Link>
                    <Link className="nav-link" id="nav-POINTS-tab" data-toggle="tab" to={`/points/view/${id}`} role="tab" aria-controls="nav-POINTS" aria-selected="false">POINTS</Link>
                </div>
            </nav>
            <div className="tab-pane fade show active" id="nav-ÉVÉNEMENTS" role="tabpanel" aria-labelledby="nav-ÉVÉNEMENTS-tab">

                    <div className="card-content">
                        <div className="card-content-header">
                            <div className="card-content-title">
                                ÉVÉNEMENTS
                            </div>
                            {/* <div className="card-content-tool">
                                <a href="#">
                                    Tout voir
                                </a>
                            </div> */}
                        </div>
                        <div className="card-content-body">
                            <table className="table table-striped table-bordered list-events">
                            {events.map((event) => (
                                <tbody>
                                    <tr>
                                        <td>{event.datetime}</td>
                                        <td>{event.name}</td>
                                        <td>{event.event_type.libelle}</td>
                                        <td>
                                            <a href="#">
                                                <img src={edit} alt="" />voir
                                            </a>
                                        </td>
                                        <td>
                                            <Link onClick={() => deleteEvents(event.id)} >
                                                <img src={supEvent} alt="" />
                                            </Link>
                                        </td>
                                    </tr>
                                    
                                </tbody> 
                            ))}
                            </table>
                        </div>
                    </div>
                   
                </div>
            </div>
    )
}
export default ViewLicence  