import React, {useEffect, useState} from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import {Link} from 'react-router-dom'
import DayJS from 'react-dayjs';



const UserTable = () => {

   const [users, setUsers] = useState([]);
   const [error, setError] = useState(null);
   const [searchTerm, setSearchTerm] = useState("");
   
   const myValue = JSON.parse(localStorage.getItem('user'))['token'];
   
   console.log(users)
   

    const handleSearchTerm = (e) => {
      let value = e.target.value;
      setSearchTerm(value)
    }


  
      

    useEffect(() => {

      try {
        const config = {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-token-api': myValue
          }
        };
        // Envoyer les informations à notre server avec fetch
        fetch(
          process.env.REACT_APP_API_URL + '/user/list',
          config
        ).then(response => response.json())
        .then(result => setUsers(result));

        // console.log('The res')
        // console.log(res)
        // debugger
       
      } catch (error) {
        console.error(error);
        setError(error);
      }
        
    }, [])
    

    return (
       
    <div>
        <input type="text" placeholder="Rechercher un utilisateur" onChange={handleSearchTerm} />
    </div>

    )
}

export default UserTable
