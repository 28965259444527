import React, {useState, useEffect} from 'react'
import './style.css'; 
import { Link } from 'react-router-dom'
import { useHistory } from "react-router";
import { Scrollbars } from 'react-custom-scrollbars';
import LogoSVG from '../img/logo.svg'
import Connect from '../img/Se connecter.png'
import IconDoc from '../img/icon-doc.svg';
import IconUser from '../img/icon-user.svg';
import World from '../img/icon-world.svg';
import cog from '../img/icon-cog.svg';
import IconUser2 from '../img/icon-user-2.svg';
import { DocumentCount } from './users/DocumentCount';




export default function Sidebar() {

const history = useHistory();

const logOut = () => {
    localStorage.user = JSON.stringify({});
    history.push("/login");
};

const myValue = JSON.parse(localStorage.getItem('user'))['token'];
const myId = JSON.parse(localStorage.getItem('user'))['id'];

const [users, setUser] = useState({
    lastname: "",
    firstname: "",
    email: "",
    address: "",
    birthday: "",
    is_active: ""
});

useEffect(() => {
    loadUser();
}, [])

const loadUser = async () => {

    try {
        const config = {
        method: 'GET',
        // mode: 'no-cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-token-api': myValue
        }
        };
        // Envoyer les informations à notre server avec fetch
        const res = fetch(
            process.env.REACT_APP_API_URL + `/user/${myId}`,
        config
        ).then(response => response.json())
        .then(result => setUser(result));
    
    } catch (error) {
        console.error(error);
        // setError(error);
    }
    
    
    }

 

  return (
      <div className="aside">
          <Scrollbars >
      <div className="logo">
          <Link to ="/">
              <img src={LogoSVG} width="190" alt="" />
              <img src={Connect} className="img-fluid" alt="" />
          </Link>
      </div>
      <div className="menu">
          <ul className="nav flex-column">
              <li className="nav-item">
                  <p className="ptitre">Bonjour {users.firstname}</p>
              </li>
              <li className="nav-item">
                  <Link className="nav-link" to={`/users/view/${myId}`}>Modifier mon profil</Link>
              </li>
          </ul>
          <ul className="nav flex-column">
              <li className="nav-item nav-title">
                  <img src={IconDoc} alt="" /> GESTION DES DOCUMENTS
              </li>
              <li className="nav-item">
                  <Link className="nav-link" to="/documents">
                      Documents en attente de validation
                      <span className="badge badge-dark"><DocumentCount /></span>
                  </Link>
              </li>
          </ul>
          <ul className="nav flex-column">
              <li className="nav-item nav-title">
                  <img src={IconUser} alt="" /> GESTION DES UTILISATEURS CODENEKT
              </li>
              <li className="nav-item">
                  <Link className="nav-link" to="/users">
                      Voir la liste des utilisateurs
                  </Link>
              </li>
          </ul>
          <ul className="nav flex-column">
              <li className="nav-item nav-title">
                  <img src={World} alt="" /> GESTION DES ENSEIGNES
              </li>
              <li className="nav-item">
                  <Link className="nav-link" to="/sign">
                      Toutes les enseignes
                  </Link>
              </li>
              <li className="nav-item">
                  <Link className="nav-link" to="/sign/add">
                      Ajouter une enseigne
                  </Link>
              </li>
          </ul>
          <ul className="nav flex-column">
              <li className="nav-item nav-title">
                  <img src={cog} alt="" /> GESTION DES COMPTES ADMIN
              </li>
              <li className="nav-item">
                  <Link className="nav-link" to="/admin">
                      Tous les comptes
                  </Link>
              </li>
              <li className="nav-item">
                  <Link className="nav-link" to="/admin/add">
                      Ajouter un compte
                  </Link>
              </li>
          </ul>
      </div>
      <div className="loggout">
          <button type="button" onClick={() => logOut()} className="btn btn-outline-secondary">
              SE DÉCONNECTER
          </button>
      </div>
</Scrollbars>
</div>
  )

  
}

