import React from 'react';
import Home from './pages/Home';
import Login from './pages/Login'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop';
import PrivateRoute from './PrivateRoute';
import Documents from './pages/Documents';
import ListUsers from './pages/ListUsers';
import AdminTable from './pages/Admin';
import SignTable from './pages/Sign';
import AddAdmin from './pages/AddAdmin';
import AddSign from './pages/AddSign';
import EditUser from './pages/EditUser';
import NotFound from './components/NotFound';
import ViewUser from './pages/ViewUser';
import EditSign from './pages/EditSign';
import UpdateAdmin from './pages/UpdateAdmin';
import LicenceView from './pages/LicenceView';
import VehiculeView from './pages/VehiculeView';
import Events from './pages/Events';
import Doc from './pages/Doc';
import Points from './pages/Points';

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <PrivateRoute exact path='/' component={Home} />
        <PrivateRoute exact path="/documents" component={Documents} />
        <PrivateRoute exact path="/users" component={ListUsers} />
        <PrivateRoute exact path="/users/edit/:id" component={EditUser} />
        <PrivateRoute exact path="/users/view/:id" component={ViewUser} />
        <PrivateRoute exact path='/licence/view/:id' component={LicenceView} />
        <PrivateRoute exact path='/vehicule/view/:id' component={VehiculeView} />
        <PrivateRoute exact path='/document/view/:id' component={Doc} />
        <PrivateRoute exact path='/points/view/:id' component={Points} />
        <PrivateRoute exact path='/events/view/:id' component={Events} />
        <PrivateRoute exact path='/admin' component={AdminTable} />
        <PrivateRoute exact path='/admin/add' component={AddAdmin} />
        <PrivateRoute exact path='/admin/edit/:id' component={UpdateAdmin} />
        <PrivateRoute exact path='/sign' component={SignTable} />
        <PrivateRoute exact path='/sign/add' component={AddSign} />
        <PrivateRoute exact path='/sign/edit/:id' component={EditSign} />
        <Route path='/login' component={Login} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}