import React, {useEffect, useState} from 'react'
import IconDoc from '../../img/icon-doc.svg';
import IconPrevius from '../../img/icon-page-prev.svg';
import IconNext from '../../img/icon-page-next.svg';
import IconFilter from '../../img/icon-filter-up.svg';
import DayJS from 'react-dayjs';
import axios from "axios"


function Datatable( { data }) {


  return <table className="table table-striped">
  <thead>
    <tr>
      <th scope="col">
          ID
          <button>
              <img src={IconFilter} alt="" />
          </button>
      </th>
      <th scope="col">
          Date d'upload
          <button>
              <img src={IconFilter} alt="" />
          </button>
      </th>
      <th scope="col">
          Type de documents
          <button>
              <img src={IconFilter} alt="" />
          </button>
      </th>
      <th scope="col">
          Statut
          <button>
              <img src={IconFilter} alt="" />
          </button>
      </th>
      <th scope="col">
          Utilisateur
          <button>
              <img src={IconFilter} alt="" />
          </button>
      </th>
 
    </tr>
  </thead>
  <tbody>
  {(data.map((doc) => (
      
          <tr>
          {/* <th scope="col">
          ID
              <button>
                  <img src={IconFilter} alt="" />
              </button>
          </th> */}
              {/* <th scope="row">{doc.id}</th> */}
              <td>{doc.id}</td>
              <td><DayJS format="DD-MM-YYYY">{doc.created_at}</DayJS></td>
              <td>{doc.name}</td>
              <td>{doc.is_verified === 0 ? "en attente de vérif" : doc.status_verified === null ? doc.status_verified : "refusé" }</td>
              <td>{doc.firstname}</td>
              <td>  
                <div className="card-c-info">
                  <button>
                  <i className="fa fa-eye" aria-hidden="true"></i>
                  <a style={{color: "#FFFFFF"}} href={doc.scan_id}>CONSULTER</a>
                  </button>
                  <button className="bg-gray">
                      <i className="fa fa-download" aria-hidden="true"></i>
                      <a style={{backgroundColor: "#FFFFFF"}} href={doc.scan_id} title="ImageName" download="custom-filename.jpg">TÉLÉCHARGER</a>
                  </button>                             
                  <button  className="content-btn-activer">
                  ✔️ VALIDER
                  </button>
                  <button  className="content-btn-delete">
                  ⛔ REFUSER
                  </button>
                  <button  className="content-btn-delete">
                  ❌ SUPPRIMER
                  </button>
                </div>
              </td>
            </tr>
          
      )))}
      </tbody>
  </table>;
}

export default Datatable;
