import { Link } from 'react-router-dom'
import AddSign from '../components/signs/AddSign'
import World from '../img/icon-world.svg';


export default function ListSign() {
  
  return (
    <>
      <div className="toolbox">
                <div className="user-id">
                    <img src={World} width="16" alt="" /> AJOUTER UNE ENSEIGNE

                </div>
                <Link to="/sign" className="back-btn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i> Retour à la liste des enseignes
                </Link>
      </div>
      <AddSign />
    </>
  )
}
