import React from 'react'
import ViewLicence from '../components/users/ViewLicence'

export default function LicenceView() {
    return (
        <div>
            <ViewLicence />
        </div>
    )
}
