import React from 'react'
import Document from '../components/users/Document'

export default function Doc() {
    return (
        <div>
            <Document />
        </div>
    )
}
