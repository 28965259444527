import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory, useParams } from "react-router-dom";

const EditAdmin = () => {
    
    let history = useHistory();
    const { id } = useParams();

    const myValue = JSON.parse(localStorage.getItem('user'))['token'];
    
    const [admin, setAdmin] = useState({
        lastname: "",
        firstname: "",
        email: "",
        is_admin: "1",
        password: "",
        address: {
            raw: ""
          },
          birthday: "" 
    });

    const { lastname, firstname, email, is_admin, password, birthday, address } = admin;
    
    function buttonToggle()
    {
        let button;
        if (admin.suspended_at === null) {
            button =  <button onClick={() => disableUser(admin.id)} className={admin.suspended_at === null ? "content-btn-desactiver" : "btn btn-success"}>
            {admin.suspended_at === null ? "🛑  DÉSACTIVER L'ADMINISTRATEUR" : "Activer"}
        </button>
        } else {
            button =  <button onClick={() => activeUser(admin.id)} className={admin.suspended_at === null ? "btn btn-warning" : "btn btn-success"}>
            {admin.suspended_at === null ? "🛑  DÉSACTIVER L'ADMINISTRATEUR" : "✅ ACTIVIER L'ADMINISTRATEUR"}
        </button>
        }
        return (    
            <>{button}</>
           
        )
    }
    
    
    const onInputChange = e => {
        setAdmin({ ...admin, [e.target.name]: e.target.value });
        console.log(admin);
        
    };

    const onAddressChange = e => {
        setAdmin({ ...admin, address: { raw: e.target.value } });
        console.log(admin);
    };
    
    useEffect(() => {
        loadAdmins();
    }, []);
    
    const onSubmit = async e => {
      e.preventDefault();
      let param = {
        "firstname": admin.firstname,
        "lastname": admin.lastname,
        "email": admin.email,
        "is_admin": admin.is_admin,
        "password": admin.password,
        "birthday": admin.birthday,
        "address": { 
            raw: admin.address.raw,
          }
      };
      console.log(param);
      
    const config = {
        method: 'PUT',
        // mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-token-api': myValue
        }
      };

      const res = await axios.put(process.env.REACT_APP_API_URL + `/user/${id}/update`, param, config);
      history.push("/admin");

      // debugger
    // console.log(res)
    // window.location.reload(false);    
};

    const deleteUser = async id => {

        const config = {
            method: 'DELETE',
            // mode: 'no-cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-token-api': myValue
        }
      };
     
        await axios.delete(process.env.REACT_APP_API_URL + `/user/${id}`, config).then(() => 
        {           
            loadAdmins(); 
            history.push("/admin");
            window.location.reload(false);    
         });
         
    };

    const activeUser = async id => {
        const config2 = {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-token-api': myValue
          }
        };
   
       await axios.get(process.env.REACT_APP_API_URL + `/user/active/${id}`, config2).then(() => {
         
        loadAdmins(); 
        // window.location.reload(false);
              
  
       });
  
    }

    const disableUser = async id => {
        const config2 = {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-token-api': myValue
          }
        };
   
       await axios.get(process.env.REACT_APP_API_URL + `/user/disable/${id}`, config2).then(() => {
         
        loadAdmins(); 
        // window.location.reload(false);
              
  
       });
  
    }

    const loadAdmins = async () => {
        try {
            const config = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'X-token-api': myValue
                }
            };
            const res = fetch(
                process.env.REACT_APP_API_URL + `/user/${id}`,
              config
            ).then(response => response.json())
            .then(result =>  {
                if(result.address == null){
                  result.address = {raw: ""}
                }
                setAdmin(result)
              });

            console.log('The rest')
            console.log((res))

        }catch(error){
            console.log(error)
        }
    }

    return (
       
        <div className="tab-content" id="nav-tabContent">
            <form onSubmit={e => onSubmit(e)}>
                <div className="tab-pane fade show active" id="nav-IDENTITE" role="tabpanel" aria-labelledby="nav-IDENTITE-tab">
                    <div className="card-content">
                        <div className="card-content-header">
                            <div className="card-content-title">
                                IDENTITE ADMIN
                            </div>
                            
                        </div>
                        <div className="card-content-body">
                            <div className="card-l-info">
                                <div className="card-a-info">
                                 Prénom : 
                                </div>
                                <div className="card-b-info">
                                <input type="text" className="form-control"
                                onChange={e => onInputChange(e)} name="firstname" value={firstname}
                                required />
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    NOM :
                                </div>
                                <div className="card-b-info">
                                <input type="text" className="form-control" value={lastname}
                                onChange={e => onInputChange(e)}  name="lastname"            
                                required />                            
                                </div>
                            </div>
                            {/* <div className="card-separator"></div> */}
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    Email :
                                </div>
                                <div className="card-b-info">
                                <input type="email" className="form-control" value={email}
                                onChange={e => onInputChange(e)} name="email" 
                                required />
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                Mot de passe :
                                </div>
                                <div className="card-b-info">
                                <input type="text" className="form-control" value={password}
                                onChange={e => onInputChange(e)} name="password" 
                                 />
                                </div>
                            </div>
                            <div className="card-l-info">
                        <div className="card-a-info">
                            né le :
                        </div>
                        <div className="card-b-info">
                        <input type="date" name="birthday" className="form-control" onChange={e => onInputChange(e)} value={birthday} />
                        </div>
                    </div>
                    <div className="card-l-info">
                        <div className="card-a-info">
                            ADRESSE :
                        </div>
                        <div className="card-b-info">
                        <input type="text" name="raw" className="form-control" onChange={e => onAddressChange(e)} value={address.raw} />
                        </div>
                    </div>
                            <div className="card-l-info">
                                <div className="card-a-info mr-8">
                                    ADMINISTRATEUR
                                </div>
                                <div className="card-b-info">
                                <input className="form-check-input ml-1" type="checkbox"  
                                onChange={e => onInputChange(e)} name="is_admin" value={is_admin}
                                checked required />
                 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="content-btn">
                 <button type="submit" className="content-btn-activer-bt"> 
                 ✅ MODIFIER L'ADMINISTRATEUR
                </button>
                {buttonToggle()} 
                {/* <button onClick={ () => disableUser(admin.id)} className="content-btn-desactiver">
                🛑  DÉSACTIVER L'ADMINISTRATEUR
                </button> */}
                <button onClick={() => deleteUser(admin.id)} className="content-btn-delete">
                ❌  SUPPRIMER L'ADMINISTRATEUR 
                </button>  
            </div>
         </div>
        </form>
    </div>
    );
};

export default EditAdmin;


