import firebase from "firebase";


const firebaseConfig = {
    apiKey: 'AIzaSyDmw_AeAy6SH5JiQRXswO36vpPKj5G8VUY',
    authDomain: '<your-auth-domain>',
    databaseURL: '<your-database-url>',
    storageBucket: 'gs://'+process.env.REACT_APP_BUCKET,
  };

firebase.initializeApp(firebaseConfig);
export var storage = firebase.storage();

export const uploadImage = async (image) => {
  const uploadToFirebase = (blob) => {
      return new Promise((resolve, reject)=>{
          var storageRef = storage.ref();
          storageRef.child(`${new Date().getTime()}.jpg`).put(blob, {
              contentType: 'image/jpeg'
          })
          .then((snapshot)=>{
            resolve(snapshot);
          }).catch((error)=>{
              console.log('error',error)
              reject(error);
          });
      });
    }
  try {
      return new Promise((resolve,reject) => {
          uploadToFirebase(image)
          .then((result) =>{
              resolve('https://firebasestorage.googleapis.com/v0/b/'+process.env.REACT_APP_BUCKET+'/o/' + result.metadata.fullPath + "?alt=media")
          })
          .catch((error) =>{
              console.log('error', error)
              reject(error)
          })
      })
  } catch (error) {
      console.log(error);
  }
}

export default uploadImage