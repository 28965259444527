import React, { useState } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";


const AddAdmin = () => {
    
    let history = useHistory();
    const myValue = JSON.parse(localStorage.getItem('user'))['token'];
    
    const [admin, setAdmin] = useState({
        lastname: "",
        firstname: "",
        email: "",
        is_admin: "1",
        password: "",
        address: {
            raw: ""
          },
          birthday: "" 
    });

    const { lastname, firstname, email, is_admin, password, birthday, address } = admin;
    
    const onInputChange = e => {
        setAdmin({ ...admin, [e.target.name]: e.target.value });
        console.log(admin);
        
    };

    const onAddressChange = e => {
        setAdmin({ ...admin, address: { raw: e.target.value } });
        console.log(admin);
    };
    
    
    const onSubmit = async e => {
      e.preventDefault();
      let param = {
        "firstname": admin.firstname,
        "lastname": admin.lastname,
        "email": admin.email,
        "is_admin": admin.is_admin,
        "password": admin.password,
        "birthday": admin.birthday,
        "address": { 
            raw: admin.address.raw,
          }
      };
      console.log(param);
      
    const config = {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-token-api': myValue
        }
      };

    await axios.post(process.env.REACT_APP_API_URL + "/user/create", param, config);
    // debugger
    // console.log(res)
    history.push("/admin");
    };

   
   
    return (
    
        <div className="tab-content" id="nav-tabContent">
            <form onSubmit={e => onSubmit(e)}>
                <div className="tab-pane fade show active" id="nav-IDENTITE" role="tabpanel" aria-labelledby="nav-IDENTITE-tab">
                    <div className="card-content">
                        <div className="card-content-header">
                            <div className="card-content-title">
                                IDENTITE ADMIN
                            </div>
                            
                        </div>
                        <div className="card-content-body">
                            <div className="card-l-info">
                                <div className="card-a-info">
                                 Prénom : 
                                </div>
                                <div className="card-b-info">
                                <input type="text" className="form-control"
                                onChange={e => onInputChange(e)} name="firstname" value={firstname}
                                required />
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    NOM :
                                </div>
                                <div className="card-b-info">
                                <input type="text" className="form-control" value={lastname}
                                onChange={e => onInputChange(e)}  name="lastname"            
                                required />                            
                                </div>
                            </div>
                            {/* <div className="card-separator"></div> */}
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    Email :
                                </div>
                                <div className="card-b-info">
                                <input type="email" className="form-control" value={email}
                                onChange={e => onInputChange(e)} name="email" 
                                required />
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                Mot de passe :
                                </div>
                                <div className="card-b-info">
                                <input type="text" className="form-control" value={password}
                                onChange={e => onInputChange(e)} name="password" 
                                required />
                                </div>
                            </div>
                            <div className="card-l-info">
                        <div className="card-a-info">
                            né le :
                        </div>
                        <div className="card-b-info">
                        <input type="date" name="birthday" className="form-control" onChange={e => onInputChange(e)} value={birthday} />
                        </div>
                    </div>
                    <div className="card-l-info">
                        <div className="card-a-info">
                            ADRESSE :
                        </div>
                        <div className="card-b-info">
                        <input type="text" name="raw" className="form-control" onChange={e => onAddressChange(e)} value={address.raw} required/>
                        </div>
                    </div>
                            <div className="card-l-info">
                                <div className="card-a-info mr-8">
                                    ADMINISTRATEUR
                                </div>
                                <div className="card-b-info">
                                <input className="form-check-input ml-1" type="checkbox"  
                                onChange={e => onInputChange(e)} name="is_admin" value={is_admin}
                                checked required />
                 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="content-btn">
              
                    <button  type="submit" className="content-btn-activer-bt">
                    ✅ AJOUTER ADMIN
                    </button>
                    {/* <button  type="reset" className="content-btn-delete">
                        REINITIALISER
                    </button> */}
                </div>
         </div>
        </form>
    </div>
    );
};

export default AddAdmin;
