import React from "react";
import { Redirect, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

const user = localStorage.user ? JSON.parse(localStorage.user) : {};
const PrivateRoute = ({ component: Component, path, ...rest }) => (
  <Route
    path={path}
    {...rest}
    render={(props) =>
      user.token ? (
        <Dashboard>
          <Component {...props} />
        </Dashboard>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              prevLocation: path,
              error: "You need to login first!",
            },
          }}
        />
      )
    }
  />
);
export default PrivateRoute;
