import React, {useEffect, useState} from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import { useParams, Link } from "react-router-dom";
import axios from 'axios';
import { useHistory } from 'react-router';

const SignTable = () => {

  const [sign, setSign] = useState({current_page: 1, data: [], per_page: 10});
  const [error, setError] = useState(null);


  const myValue = JSON.parse(localStorage.getItem('user'))['token'];

  // const history = useHistory();
  // const { id } = useParams();
  
  const deleteSign = async id => {

    const config = {
        method: 'DELETE',
        // mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-token-api': myValue
    }
  };
 
     await axios.delete(process.env.REACT_APP_API_URL + `/sign/delete/${id}`, config).then(() => {
       
            loadSign(sign.current_page, sign.per_page); 
            // window.location.reload(false);
            

     });
      // history.push("/sign");
     // setSign()
    };

    
    const columns = [
        {dataField:'id', text: 'ID', sort: true},
        {dataField:'name', text: 'Nom', sort: true},
        {dataField:'address.raw', text: 'Adresse', sort: true},
        {dataField:'category.name', text: 'Catégorie', sort: true},
        {
            dataField: 'idd',
            text: '',
            formatter: (sign, signs) => {
              return (   
                
                  <Link className="btn btn-primary" to={`/sign/edit/${signs.id}`}>
                    Modifier
                </Link>
                
                
              )
            }
        },
        {
            dataField: 'idddd',
            text: '',
            formatter: (sign, signss) => {
              return (   
                
                  <button className="btn btn-danger" onClick={() => deleteSign(signss.id)}>
                    Supprimer
                  </button> 
                
                
                
              )
            }
        }
       
    ]

    const handleOnPageChange = (page, sizePerPage) => {
      loadSign(page, sizePerPage)
    }

    const RemotePagination = ({ data, page, sizePerPage, totalSize }) => (
      <div>
        <PaginationProvider
          pagination={
            paginationFactory({
              custom: true,
              page,
              sizePerPage,
              totalSize,
              onPageChange: (page, sizePerPage) => handleOnPageChange(page, sizePerPage)
            })
          }
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
              <div className="tab-user pt-3" style={{width: "1000px"}}>
                              
               <BootstrapTable bootstrap4
                    classes="table table-striped"
                    remote
                    keyField='id'
                    data={ data }
                    columns={ columns }
                    { ...paginationTableProps }
                    filter={filterFactory()}
                    striped bordered hover
                  /> 
                    <div>
                      <PaginationListStandalone
                        { ...paginationProps }
                      />
                    </div>
              </div>
            )
          }
        </PaginationProvider>
      </div>
    );

    useEffect(() => {
      loadSign(sign.current_page, sign.per_page);
    }, []);

    const loadSign = async (current_page, per_page) =>  {
      
       try {
            const config = {
              method: 'GET',
              // mode: 'no-cors',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-token-api': myValue
              } 
            };
            // Envoyer les informations à notre server avec fetch
            const res = fetch(
              process.env.REACT_APP_API_URL + `/sign/list/${current_page}/${per_page}`,
              config
            ).then(response => response.json())
            .then(result => {
              setSign(result)
            });
           
          } catch (error) {
            console.error(error);
            setError(error);
          }

    };


    if(sign.data.length > 0){
      return (
        <div>
            <RemotePagination
              data={ sign.data }
              page={ sign.current_page }
              // filter={filterFactory()}
              sizePerPage={ sign.per_page }
              totalSize={sign.total}
            />
        </div>
        
      )
    }else{
      return <div><h3>Aucune données dans le tableau</h3></div>
    }
}

export default SignTable
