import React, {useState, useEffect} from 'react'
import { useParams, Link } from "react-router-dom";
import users2 from '../../img/icon-user-2.svg';
import iconUp from '../../img/icon-trend-up.svg';
import iconDown from '../../img/icon-trend-down.svg';
import DayJS from 'react-dayjs';




const Point = () => {

    const myValue = JSON.parse(localStorage.getItem('user'))['token'];


    const [points, setPoints] = useState([]);
    const [pointsTotal, setPointsTotal] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        loadPointList();
        Total();
    }, []);


    const loadPointList = async () => {

        try {
         const config = {
           method: 'GET',
           // mode: 'no-cors',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'X-token-api': myValue
           }
         };
         // Envoyer les informations à notre server avec fetch
         fetch(
            process.env.REACT_APP_API_URL + `/point/list/${id}`,
           config
         ).then(response => response.json())
         .then(result => setPoints(result));
              
       } catch (error) {
         console.error(error);
         // setError(error);
       }

     }

     const Total = async () => {
        try {
            const config = {
              method: 'GET',
              // mode: 'no-cors',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-token-api': myValue
              }
            };
            // Envoyer les informations à notre server avec fetch
            fetch(
               process.env.REACT_APP_API_URL + `/point/total/${id}`,
              config
            ).then(response => response.json())
            .then(result => setPointsTotal(result));
                 
          } catch (error) {
            console.error(error);
            // setError(error);
          }
     }
    

    return (
      <div>
      
      <div className="toolbox">
                <div className="user-id">
                    <img src={users2} width="16" alt="" /> USER ID { id }
                </div>
                <Link to="/users" className="back-btn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i> Retour à la liste des utilisateurs
                </Link>
      </div>
            <nav className="content-nav">
                <div className="nav" id="nav-tab" role="tablist">
                    <Link className="nav-link" id="nav-IDENTITE-tab" data-toggle="tab" to={`/users/view/${id}`} role="tab" aria-controls="nav-IDENTITE" aria-selected="true">IDENTITE</Link>
                    <Link className="nav-link" id="nav-PERMIS-tab" data-toggle="tab" to={`/licence/view/${id}`} role="tab" aria-controls="nav-PERMIS" aria-selected="false">PERMIS</Link>
                    <Link className="nav-link" id="nav-VEHICULES-tab" data-toggle="tab" to={`/vehicule/view/${id}`} role="tab" aria-controls="nav-VEHICULES" aria-selected="false">VEHICULES</Link>
                    <Link className="nav-link" id="nav-ÉVÉNEMENTS-tab" data-toggle="tab" to={`/events/view/${id}`} role="tab" aria-controls="nav-ÉVÉNEMENTS" aria-selected="false">ÉVÉNEMENTS</Link>
                    <Link className="nav-link" id="nav-DOCUMENTS-tab" data-toggle="tab" to={`/document/view/${ id }`} role="tab" aria-controls="nav-DOCUMENTS" aria-selected="false">DOCUMENTS</Link>
                    <Link className="nav-link active" id="nav-POINTS-tab" data-toggle="tab" to={`/points/view/${ id }`} role="tab" aria-controls="nav-POINTS" aria-selected="false">POINTS</Link>
                </div>
            </nav>          
            <div className="tab-pane fade show active" id="nav-POINTS" role="tabpanel" aria-labelledby="nav-POINTS-tab">
                    <div className="card-content">
                        <div className="card-content-header">
                            <div className="card-content-title">
                                POINTS
                            </div>
                            <div className="card-content-info">
                                <span className="card-content-info-1">
                                    SOLDE : {pointsTotal} points
                                </span>
                                <a href="#" className="card-content-info-2">
                                    offrir des points 
                                </a>
                            </div>
                            {/* <div className="card-content-tool">
                                <a href="#">
                                    Tout voir
                                </a>
                            </div> */}
                        </div>
                        <div className="card-content-body">
                            <table className="table table-striped table-bordered list-points">
                            <tbody>
                            {points.map((point) => (
                                    <tr>
                                        <td className="trend-up">
                                        <img src={iconUp} alt="" /> +{point.number} points
                                        </td>
                                        <td><DayJS format="DD-MM-YYYY">{point.created_at}</DayJS></td>
                                        <td>{point.type}</td>
                                    </tr>
                                     ))}
                                </tbody> 
                            </table>
                        </div>
                    </div>
                </div>
            </div>
                
            
          
    )
}

export default Point;