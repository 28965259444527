import React, {useState, useEffect} from 'react'
import { useParams, Link } from "react-router-dom";
import users2 from '../../img/icon-user-2.svg';
import DayJS from 'react-dayjs';




const ViewUsers = () => {

    const myValue = JSON.parse(localStorage.getItem('user'))['token'];

    const [docs, setDocs] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        loadDocs();
    }, []);

    const loadDocs = async () => 
    {

        try {
         const config = {
           method: 'POST',
           // mode: 'no-cors',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'X-token-api': myValue
           }
         };
         // Envoyer les informations à notre server avec fetch
         fetch(
            process.env.REACT_APP_API_URL + "/strongbox/list",
           config
         ).then(response => response.json())
         .then(result => setDocs(result));
              
       } catch (error) {
         console.error(error);
         // setError(error);
       }

    }


    return (
      <div>
      
      <div className="toolbox">
                <div className="user-id">
                    <img src={users2} width="16" alt="" /> USER ID { id }
                </div>
                <Link to="/users" className="back-btn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i> Retour à la liste des utilisateurs
                </Link>
      </div>
            <nav className="content-nav">
                <div className="nav" id="nav-tab" role="tablist">
                    <Link className="nav-link" id="nav-IDENTITE-tab" data-toggle="tab" to={`/users/view/${ id }`} role="tab" aria-controls="nav-IDENTITE" aria-selected="true">IDENTITE</Link>
                    <Link className="nav-link" id="nav-PERMIS-tab" data-toggle="tab" to={`/licence/view/${ id }`} role="tab" aria-controls="nav-PERMIS" aria-selected="false">PERMIS</Link>
                    <Link className="nav-link" id="nav-VEHICULES-tab" data-toggle="tab" to={`/vehicule/view/${ id }`} role="tab" aria-controls="nav-VEHICULES" aria-selected="false">VEHICULES</Link>
                    <Link className="nav-link" id="nav-ÉVÉNEMENTS-tab" data-toggle="tab" to={`/events/view/${ id }`} role="tab" aria-controls="nav-ÉVÉNEMENTS" aria-selected="false">ÉVÉNEMENTS</Link>
                    <Link className="nav-link active" id="nav-DOCUMENTS-tab" data-toggle="tab" to={`/document/view/${ id }`} role="tab" aria-controls="nav-DOCUMENTS" aria-selected="false">DOCUMENTS</Link>
                    <Link className="nav-link" id="nav-POINTS-tab" data-toggle="tab" to={`/points/view/${ id }`} role="tab" aria-controls="nav-POINTS" aria-selected="false">POINTS</Link>
                </div>
            </nav>          
            <div className="tab-pane fade show active" id="nav-DOCUMENTS" role="tabpanel" aria-labelledby="nav-DOCUMENTS-tab">
                    <div className="card-content">
                        <div className="card-content-header">
                            <div className="card-content-title">
                                DOCUMENTS
                            </div>
                            <div className="card-content-tool">
                                <a href="#">
                                    Tout voir
                                </a>
                            </div>
                        </div>
                        <div className="card-content-body">
                            <table className="table table-striped table-bordered list-docs">
                            {docs.map((doc) => (

                                <tbody>
                                    <tr>
                                        <td>{doc.name}</td>
                                        <td>{doc.type}</td>
                                        <td><DayJS format="DD-MM-YYYY">{doc.created_at}</DayJS></td>
                                        <td>                                           <div className="card-c-info">
                                        <button>
                                       
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                        <a style={{color: "#FFFFFF"}} href={doc.scan_id}>CONSULTER</a>
                                    </button>
                                    <button className="bg-gray">
                                        <i className="fa fa-download" aria-hidden="true"></i>
                                        <a style={{color: "#FFFFFF"}} href={doc.scan_id} title="ImageName" download="custom-filename.jpg">TÉLÉCHARGER</a>
                                        
                                    </button>
                                            </div>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
          
    )
}

export default ViewUsers;