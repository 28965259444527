import React, {useState, useEffect} from 'react'
import { useParams, Link } from "react-router-dom";
import users2 from '../../img/icon-user-2.svg';
import edit from '../../img/icon-edit.svg';
import axios from 'axios';
import { useHistory } from 'react-router';


const ViewUsers = () => {

    const myValue = JSON.parse(localStorage.getItem('user'))['token'];

    const [users, setUser] = useState({
        lastname: "",
        firstname: "",
        email: "",
        address: "",
        birthday: "",
        suspended_at: ""
    });

    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        loadUser();
    }, []);


    const deleteUser = async id => {

        const config = {
            method: 'DELETE',
            // mode: 'no-cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-token-api': myValue
        }
      };
     
        await axios.delete(process.env.REACT_APP_API_URL + `/user/${id}`, config).then(() => 
        {           
            loadUser(); 
            history.push("/users");
            window.location.reload(false);    
         });
         
    };

    const activeUser = async id => {
        const config2 = {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-token-api': myValue
          }
        };
   
       await axios.get(process.env.REACT_APP_API_URL + `/user/active/${id}`, config2).then(() => {
         
        loadUser(); 
        // window.location.reload(false);
              
  
       });
  
    }

    const disableUser = async id => {
        const config2 = {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-token-api': myValue
          }
        };
   
       await axios.get(process.env.REACT_APP_API_URL + `/user/disable/${id}`, config2).then(() => {
         
        loadUser(); 
        // window.location.reload(false);
              
  
       });
  
    }

    const loadUser = async () => {

        try {
         const config = {
           method: 'GET',
           // mode: 'no-cors',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'X-token-api': myValue
           }
         };
         // Envoyer les informations à notre server avec fetch
         fetch(
             process.env.REACT_APP_API_URL + `/user/${id}`,
           config
         ).then(response => response.json())
         .then(result => setUser(result));
              
       } catch (error) {
         console.error(error);
         // setError(error);
       }

     }

    return (
      <div>

      
      <div className="toolbox">
                <div className="user-id">
                    <img src={users2} width="16" alt="" /> USER ID {users.id}
                </div>
                <Link to="/users" className="back-btn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i> Retour à la liste des utilisateurs
                </Link>
      </div>
            <nav className="content-nav">
                <div className="nav" id="nav-tab" role="tablist">
                    <Link className="nav-link active" id="nav-IDENTITE-tab" data-toggle="tab" to={`/users/view/${users.id}`} role="tab" aria-controls="nav-IDENTITE" aria-selected="true">IDENTITE</Link>
                    <Link className="nav-link" id="nav-PERMIS-tab" data-toggle="tab" to={`/licence/view/${users.id}`} role="tab" aria-controls="nav-PERMIS" aria-selected="false">PERMIS</Link>
                    <Link className="nav-link" id="nav-VEHICULES-tab" data-toggle="tab" to={`/vehicule/view/${users.id}`} role="tab" aria-controls="nav-VEHICULES" aria-selected="false">VEHICULES</Link>
                    <Link className="nav-link" id="nav-ÉVÉNEMENTS-tab" data-toggle="tab" to={`/events/view/${users.id}`} role="tab" aria-controls="nav-ÉVÉNEMENTS" aria-selected="false">ÉVÉNEMENTS</Link>
                    <Link className="nav-link" id="nav-DOCUMENTS-tab" data-toggle="tab" to={`/document/view/${users.id}`} role="tab" aria-controls="nav-DOCUMENTS" aria-selected="false">DOCUMENTS</Link>
                    <Link className="nav-link" id="nav-POINTS-tab" data-toggle="tab" to={`/points/view/${users.id}`} role="tab" aria-controls="nav-POINTS" aria-selected="false">POINTS</Link>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-IDENTITE" role="tabpanel" aria-labelledby="nav-IDENTITE-tab">
                    <div className="card-content">
                        <div className="card-content-header">
                            <div className="card-content-title">
                                IDENTITE
                            </div>
                            <div className="card-content-tool">
                                <Link  to={`/users/edit/${users.id}`}>
                                    <img src={edit} alt="" />modifier
                                </Link>
                            </div>
                        </div>
                        <div className="card-content-body">
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    ID :
                                </div>
                                <div className="card-b-info">
                                    {users.id}
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    STATUT :
                                </div>
                                <div className="card-b-info">
                                {users.suspended_at === null ? "✅actif" : "❌désactivé"}
                                    
                                </div>
                            </div>
                            <div className="card-separator"></div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    NOM :
                                </div>
                                <div className="card-b-info">
                                    {users.lastname}
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    prénom :
                                </div>
                                <div className="card-b-info">
                                {users.firstname}
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    EMAIL :
                                </div>
                                <div className="card-b-info">
                                {users.email}
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    né le :
                                </div>
                                <div className="card-b-info">
                                    {users.birthday}
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    ADRESSE :
                                </div>
                                <div className="card-b-info">
                                {users.address ? users.address.raw : ""}
                                </div>
                            </div>
                            {/* <div className="card-l-info">
                                <div className="card-a-info">
                                Anniversaire
                                </div>
                                <div className="card-b-info">
                                {users.birthday}
                                </div>
                            </div> */}
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    Ville :
                                </div>
                                <div className="card-b-info">
                                {users.city}
                                </div>
                            </div>
                            <div className="card-l-info">
                                <div className="card-a-info">
                                    Pays :
                                </div>
                                <div className="card-b-info">
                                {users.country}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
            <div className="content-btn">
                <button onClick={ () => activeUser(users.id)} className="content-btn-activer-bt">
                ✅ ACTIVER
                </button>
                <button onClick={ () => disableUser(users.id)} className="content-btn-desactiver">
                🛑  DÉSACTIVER
                </button>
                <button onClick={() => deleteUser(users.id)} className="content-btn-delete">
                ❌  SUPPRIMER L’UTILISATEUR
                </button>
            </div>
          </div>
    )
}

export default ViewUsers;