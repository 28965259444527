import React, {useState, useEffect} from 'react'
import './home.css';
import UserTable from '../components/users/UserTable'
import Loader from '../components/Loader'
import IconUser from '../img/icon-user-2.svg';


export default function Home(){
  const [loader, setLoader] = useState(true);

  useEffect(() => { 

    setTimeout(() => {
        setLoader(false)
    }, 2000)

  }, [])

  return loader ?
  (
    <Loader />
  ) :
  (
    <>
      <div className="toolbox">
                <div className="user-id">
                    <img src={IconUser} width="16" alt="" /> LISTE DES UTILISATEURS CODENEKT
                </div>
                <div className="filter-btn">
                    Filtrer par :
                    <select name="filter" id="filter">
                        <option value="1" selected>NOM</option>
                        <option value="2" selected>PRENOM</option>
                        <option value="3" selected>MAIL</option>
                        <option value="3" selected>STATUT</option>
                    </select>
                </div>
                <div className="search-input">
                    <input type="text" placeholder="Rechercher un utilisateur" />
                    <i className="fa fa-search" aria-hidden="true"></i>
                </div>
            </div> <br />
      <UserTable />
    </>
  );
}