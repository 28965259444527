import React from 'react'
import ViewUsers from '../components/users/ViewUser'

export default function ViewUser() {
    return (
        <div>
            <ViewUsers />
        </div>
    )
}
