import React from 'react'
import Point from '../components/users/Point'

export default function Points() {
    return (
        <div>
            <Point />
        </div>
    )
}
