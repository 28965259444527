import React, { useState } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import uploadImage from "../firebaseConfig";

const AddSign = () => {

    let history = useHistory();
    const myValue = JSON.parse(localStorage.getItem('user'))['token'];

    

    const [sign, setSign] = useState({
        name: "",
        sign_category_id: [
            {id: 1, types: "Pneu"},
            {id: 2, types: "Contrôle technique"},
            {id: 3, types: "Centre auto"},
            {id: 4, types: "Concession auto"},
            {id: 5, types: "Carrosserie / Peinture"}
            ],
        logo: "",
        phone: "",
        raw: ""
    });

    const options = [
        {id: 1, types: "Pneu"},
        {id: 2, types: "Contrôle technique"},
        {id: 3, types: "Centre auto"},
        {id: 4, types: "Concession auto"},
        {id: 5, types: "Carrosserie / Peinture"}
        ];

    const { name, sign_category_id, logo, phone, raw } = sign;
    
    const onInputChange = e => {
        setSign({ ...sign, [e.target.name]: e.target.value });
        console.log(sign);
        
    };
    const onFileChange = (e) => {
        if (e.target.files[0]){
            uploadImage(e.target.files[0]).then((result) =>
            {
                console.log('URL image', result)
                setSign({...sign, logo : result});
                // return result
            })
        }
    }
    
    const onSubmit = async e => {
      e.preventDefault();
      let param = {
        "name": sign.name,
        "logo": sign.logo,
        "phone": sign.phone,
        "address": {
          "raw": sign.raw,
           },
        "sign_category_id": sign.sign_category_id
      };
      console.log(param);
    
      const config = {
        method: 'POST',
        // mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-token-api': myValue
        }
      };

    await axios.post(process.env.REACT_APP_API_URL + "/sign/add", param, config);
    //   console.log(res)
    //   debugger
      history.push("/sign");
    };

    return (
    
    <div className="tab-content" id="nav-tabContent">
      <form onSubmit={e => onSubmit(e)}>
          <div className="tab-pane fade show active" id="nav-IDENTITE" role="tabpanel" aria-labelledby="nav-IDENTITE-tab">
              <div className="card-content">
                  <div className="card-content-header">
                      <div className="card-content-title">
                          IDENTITE ENSEIGNE
                      </div>
                      
                  </div>
                  <div className="card-content-body">
                      <div className="card-l-info">
                          <div className="card-a-info">
                          Nom Enseigne : 
                          </div>
                          <div className="card-b-info">
                          <input type="text" className="form-control" value={name} onChange={e => onInputChange(e)} name="name" required/>
                          </div>
                      </div>
                      <div className="card-l-info">
                          <div className="card-a-info">
                              Catégorie :
                          </div>
                          <div className="card-b-info">
                          <div className="input-group mb-3">
                            <select className="custom-select" name="sign_category_id" value={sign_category_id} onChange={e => onInputChange(e)} required>
                                {options.map((type) => (
                                <option value={type.id}>
                                    {type.types}
                                </option>
                                ))}
                            </select>
                     </div>                         
                    </div>
            
                </div>
                    <div className="card-l-info">
                          <div className="card-a-info">
                          Logo enseigne :
                          </div>
                          <div className="card-b-info">
                          
           
                  <div className="custom-file">
                    <input className="custom-file-input" name="logo" type="file"
                     onChange={e => onFileChange(e)} />
                     <label className="custom-file-label">Choisir un fichier</label>
                  </div>
                          <input className="custom-file-input" name="logo" type="file"
                onChange={e => onFileChange(e)} />
                          </div>
                      </div>
                      <div className="card-l-info">
                          <div className="card-a-info">
                          Téléphone :
                          </div>
                          <div className="card-b-info">
                          <input type="text" name="phone" className="form-control" onChange={e => onInputChange(e)} value={phone} required/>
                          </div>
                      </div>
                      <div className="card-l-info">
                          <div className="card-a-info mr-8">
                              Adresse complète :
                          </div>
                          <div className="card-b-info">
                          <input type="text" name="raw" className="form-control" onChange={e => onInputChange(e)} value={raw} />
           
                          </div>
                      </div>
                     
                      
                  </div>
              </div>
              <div className="content-btn">
        
              <button type="submit" className="content-btn-activer-bt">
              ✅ AJOUTER UNE ENSEIGNE 
              </button>
              {/* <button  type="reset" className="content-btn-delete">
                  REINITIALISER
              </button> */}
          </div>
   </div>
  </form>
</div>
        
    )
};

export default AddSign;
