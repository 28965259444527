import React, {useEffect, useState} from 'react'
import IconDoc from '../../img/icon-doc.svg';
import IconPrevius from '../../img/icon-page-prev.svg';
import IconNext from '../../img/icon-page-next.svg';
import Datatable from './index';


const Documents = () => {

    const myValue = JSON.parse(localStorage.getItem('user'))['token'];

    const [data, setDocs] = useState([]);
    const [q, setQ] = useState("");

    

    useEffect(() => {
        loadDocs();
    }, []);

   

    const loadDocs = async () => 
    {

        try {
         const config = {
           method: 'POST',
           // mode: 'no-cors',
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'X-token-api': myValue
           }
         };
         // Envoyer les informations à notre server avec fetch
         fetch(
            process.env.REACT_APP_API_URL + "/strongbox/list",
           config
         ).then(response => response.json())
         .then(result => setDocs(result));
              
       } catch (error) {
         console.error(error);
         // setError(error);
       }

    }

    function search(rows)
    {
      return rows.filter((row) => row.name.toLowerCase().indexOf(q) > -1);

    }

    return (
        <div>
            <div className="toolbox">
                <div className="user-id">
                    <img src={IconDoc} width="16" alt="" /> LISTE DES DOCUMENTS CODENEKT
                </div>
                <div className="filter-btn">
                    Filtrer par :
                    <select name="filter" defaultValue="3" id="filter">
                        <option value="1" >Date</option>
                        <option value="2" >Statut</option>
                        <option value="3" >Type document</option>
                        <option value="4" >ID</option>
                    </select>
                </div>
                <div className="search-input">
                    <input type="text" value={q} onChange={(e) => setQ(e.target.value)} placeholder="Rechercher un document" />
                    <i className="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <div className="tab-user pt-3 table-responsive">
                <Datatable data={search(data)} />
                </div>
                <div className="tab-pagination pt-3">
                    <a href="#" className="btn-page">
                        <img src={IconPrevius} alt="" />
                    </a>
                    <a href="#" className="page-item border-0 actif">1</a>
                    <a href="#" className="page-item">2</a>
                    <a href="#" className="page-item">3</a>
                    <a href="#" className="page-item">4</a>
                    <a href="#" className="btn-page">
                        <img src={IconNext} alt="" />
                    </a>
                </div>
            </div>
      )

}

export default Documents
