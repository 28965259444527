import React from "react";
import Sidebar from "../components/Sidebar";
import '../components/style.css';

export default function Dashboard(props) {
  return (
    <div className="wrap" style={{background:"#FFFFFF"}}>
      <Sidebar />
      <div className="content" style={{background:"#FFFFFF"}}> 
         <div>{props.children}</div>
      </div>
    </div>
  );
}
