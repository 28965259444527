import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router";
import logo from "../img/logo.png";
import './login.css';


export default function Login(props) {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  const handleEmailChange = function (e) {
    setEmail(e.target.value);
  };
  const handlePasswordChange = function (e) {
    setPassword(e.target.value);
  };
  const handleSubmit = async function (e) {
    e.preventDefault();
    try {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, password: password }),
      };

      // Envoyer les informations à notre server avec fetch
      const res = await fetch(
        process.env.REACT_APP_API_URL + "/user/login",
        config
      ).then((response) => {
        return response.json();
      });
      
      if (res && res.token) {
        localStorage.setItem("user", JSON.stringify(res));
        window.location.reload();
      }else if(res && res.message){
        setError(res.message)
      }
    } catch (error) {
      console.error(error);
      setError('EMAIL OU MOT DE PASSE INCORRECT, VEUILLEZ RÉESSAYER');
      // alert('Mot de passe incorrect. veuillez réessayer')
      // window.location.reload(false);

    }
  };

  useEffect(() => {
    let user = localStorage.user ? JSON.parse(localStorage.user) : {};
    if (user.token && user.suspended_at == null && user.is_admin == 1) {
      history.push("/");
    }
    //  else {
    //   // window.location.reload(false);
    //   setError("VOUS N'AVEZ PAS LES DROITS, VEUILLEZ CONTACTEZ L'ADMINISTRATEUR DU SITE");
    //   // alert("VOUS N'AVEZ PAS LES DROITS, VEUILLEZ CONTACTEZ L'ADMINISTRATEUR DU SITE")
    // }
  }, [history]);

  function Toggle()
  {
    
        var temp = document.getElementById("password");
        if (temp.type === "password") {
            temp.type = "text";
        }
        else {
            temp.type = "password";
        }
    
}

  return (
    
    <form className="container"  onSubmit={(e) => handleSubmit(e)}>
       <div className="header">
            <div className="logo">
                <img src={logo} alt="logo de codenekt" />
            </div>
            
            <h1 className="header__title">
                CODENEKT 
            </h1>

            <p className="header__sub">
                BACK OFFICE
            </p>
        </div>
      {error && <Alert variant="danger">{error}</Alert>}  
      <div className="form">
            <label htmlFor="email">
                <input type="email" name="email" id="email"   placeholder="Email"  value={email}  onChange={(e) => handleEmailChange(e)}
                required />
            </label>

            <label htmlFor="password" className="password-label">
                <input type="password" value={password} name="password" id="password" placeholder="Mot de passe" onChange={(e) => handlePasswordChange(e)} required />
                <i className="fa fa-eye unmask" id="unmask" onClick={Toggle} aria-hidden="true"></i>

            </label>

            <label htmlFor="remind" className="remind-label">
                <input type="checkbox" className="checkbox" />
                 Se souvenir de moi
            </label>

            <button type="submit" className="submit">SE CONNECTER</button>

            <p className="lost-pwd">
                Mot de passe oublié   
            </p>
      </div>
          
    </form>
  );
  

  
}
