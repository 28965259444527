import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory, useParams, Link } from "react-router-dom";
import users2 from '../../img/icon-user-2.svg';




const UpdateUser = () => {

    let history = useHistory();
    const { id } = useParams();

    const myValue = JSON.parse(localStorage.getItem('user'))['token'];

    const [users, setUser] = useState({
        lastname: "",
        firstname: "",
        email: "",
        address: {
          raw: ""
        },
        birthday: ""        
    });

    const { lastname, firstname, email, birthday, address} = users;
    
    const onInputChange = e => {
        setUser({ ...users, [e.target.name]: e.target.value });
        console.log(users);
        
    };

    const onAddressChange = e => {
      setUser({ ...users, address: { raw: e.target.value } });
      console.log(users);
      
    };

   
    useEffect(() => {
        loadUser();
    }, []);
    
    
    const onSubmit = async e => {
      e.preventDefault(); 
      let param = {
        id: users.id,
        lastname: users.lastname,
        firstname: users.firstname,
        email: users.email,
        address: { 
          raw: users.address.raw,
        },
        birthday: users.birthday
      };
      console.log(param);
    
      const config = {
        method: 'PUT',
        // mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-token-api': myValue
        }
      };

    await axios.put(process.env.REACT_APP_API_URL + `/user/${id}/update`, param, config);
    //   console.log(res)
    //   debugger
      history.push(`/users/view/${users.id}`);
    };
    

    const loadUser = async () => {
      
       try {
        const config = {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-token-api': myValue
          }
        };
        // Envoyer les informations à notre server avec fetch
        const res = fetch(
            process.env.REACT_APP_API_URL + `/user/${id}`,
          config
        ).then(response => response.json())
        .then(result => {
          if(result.address == null){
            result.address = {raw: ""}
          }
          setUser(result)
        });
       
      } catch (error) {
        console.error(error);
        // setError(error);
      }
    
    
    }

    

    return (
               
        <div className="tab-content" id="nav-tabContent">
          <form onSubmit={e => onSubmit(e)}>
          <div className="toolbox">
                <div className="user-id">
                    <img src={users2} width="16" alt="" /> USER ID {users.id}
                </div>
                <Link to={`/users/view/${users.id}`} className="back-btn">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i> Retour à mon profil
                </Link>
      </div>
        <div className="tab-pane fade show active" id="nav-IDENTITE" role="tabpanel" aria-labelledby="nav-IDENTITE-tab">
            <div className="card-content">
                <div className="card-content-header">
                    <div className="card-content-title">
                        MODIFIER MON PROFIL
                    </div>
                  
                </div>
                <div className="card-content-body">
                    <div className="card-l-info">
                        <div className="card-a-info">
                            ID :
                        </div>
                        <div className="card-b-info">
                            {users.id}
                        </div>
                    </div>
                    <div className="card-separator"></div>
                    <div className="card-l-info">
                        <div className="card-a-info">
                            NOM :
                        </div>
                        <div className="card-b-info">
                               <input type="text" className="form-control" value={lastname} onChange={e => onInputChange(e)} name="lastname" required/>

                        </div>
                    </div>
                    <div className="card-l-info">
                        <div className="card-a-info">
                            prénom :
                        </div>
                        <div className="card-b-info">
                        <input type="text" name="firstname" className="form-control" onChange={e => onInputChange(e)} value={firstname} required/>
                        </div>
                    </div>
                    <div className="card-l-info">
                        <div className="card-a-info">
                            EMAIL :
                        </div>
                        <div className="card-b-info">
                        <input type="text" name="email" className="form-control" onChange={e => onInputChange(e)} value={email} required/>
                        </div>
                    </div>
                    <div className="card-l-info">
                        <div className="card-a-info">
                            né le :
                        </div>
                        <div className="card-b-info">
                        <input type="date" name="birthday" className="form-control" onChange={e => onInputChange(e)} value={birthday} />
                        </div>
                    </div>
                    <div className="card-l-info">
                        <div className="card-a-info">
                            ADRESSE :
                        </div>
                        <div className="card-b-info">
                        <input type="text" name="raw" className="form-control" onChange={e => onAddressChange(e)} value={address.raw} required/>
                        </div>
                    </div>
                    {/* <div className="card-l-info">
                        <div className="card-a-info">
                            Ville :
                        </div>
                        <div className="card-b-info">
                        {users.city}
                        </div>
                    </div>
                    <div className="card-l-info">
                        <div className="card-a-info">
                            Pays :
                        </div>
                        <div className="card-b-info">
                        {users.country}
                        </div>
                    </div> */}
                </div>
            
          
          </div>
        </div>
        <div className="content-btn">
                <button type="submit" className="content-btn-activer-bt">
                ✅ MODIFIER PROFIL
                </button>
        </div>
        
        </form>
      </div>
        
    )
};

export default UpdateUser;
