import React from 'react'
import UpdateUser from '../components/users/UpdateUser'


function EditUser() {
    return (
        <div>
           
            <UpdateUser />
        </div>
    )
}

export default EditUser
