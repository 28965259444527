import React, {useState, useEffect} from 'react'

export const DocumentCount = () => {

    const myValue = JSON.parse(localStorage.getItem('user'))['token'];
    const [doc, setDocs] = useState('')

    useEffect(() => {
        TotalDoc();
    }, []);

    const TotalDoc = async () => {
        try {
            const config = {
                method: 'GET',
                headers: { 
                    'Accept': 'application/json',
                    'Content-type' : 'application/json',
                    'X-token-api' : myValue
                }
            };
            fetch(process.env.REACT_APP_API_URL + "/strongbox/verified/count", 
            config
            ).then(response => response.json())
            .then(result => setDocs(result));
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
           {doc}
        </div>
    )
}
